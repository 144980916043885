import React, {useState, useEffect} from "react";

export default function Clappr360({videoSource, playerId}) {
    const [isLibraryReady, setIsLibraryReady] = useState(false);
    useEffect(() => {
        if(!document.getElementById("clappr360") && !document.getElementById("clappr")) {
            ((d, s, id) => {
                var ls = d.querySelectorAll(s);
                var js, fjs = ls[ls.length - 1];
                js = d.createElement(s);
                js.id = id;
                js.defer = true;
                js.onload = () => {
                    ((d, s, id) => {
                        var ls = d.querySelectorAll(s);
                        var js, fjs = ls[ls.length - 1];
                        js = d.createElement(s);
                        js.id = id;
                        js.defer = true;
                        js.src = "https://cdn.jsdelivr.net/npm/clappr-video360@latest/dist/clappr-video360.min.js";
                        fjs.parentNode.insertBefore(js, fjs);
                    })(document, "script", "clappr360");
                };
                js.src = "https://cdn.jsdelivr.net/npm/clappr@latest/dist/clappr.min.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "clappr");
        }

        const getScripts = setInterval(() => {
            if (typeof window.Clappr === "undefined" || typeof window.Video360 === "undefined")
                return;
            clearInterval(getScripts);
            setIsLibraryReady(true);
        }, 50);

    }, []);

    if (!isLibraryReady) {
        return (
            <div className="clappr-wrapper">
                <div className="clappr-player" id={playerId}/>
                <p>Loading...</p>
            </div>
        );
    } else {
        const container = document.getElementById(playerId);
        if (container.childElementCount === 0) {
            const clappr_player = new window.Clappr.Player({
                source: videoSource,
                width: "100%",
                height: "100%",
                plugins: {
                    container: [window.Video360]
                }
            });

            clappr_player.attachTo(container);
            clappr_player.getPlugin("click_to_pause").disable();
        }
    }
    return (
        <div className="clappr-wrapper">
            <div className="clappr-player" id={playerId}/>
        </div>
    );
}