import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {Buffer} from 'buffer';
import moment from "moment";
import api from "./api/events";
import LoginForm from "./components/LoginForm";
import Player from "./components/Player";
import BeforeEvent from "./components/BeforeEvent";
import AfterEvent from "./components/AfterEvent";
import NoEvent from "./components/NoEvent";
import Loading from "./components/Loading";
import './assets/animation.css';

export default function Main() {

    const [error, setError] = useState("");
    const [event, setEvent] = useState({event_pw: "", start_time: "", end_time: ""});
    const [session, setSession] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const [offset, setOffset] = useState(0);

    const [eventStartTime, setEventStartTime] = useState({
        timeToStart: 9999,
        eventStarted: false
    });

    const [eventEndTime, setEventEndTime] = useState({
        timeToEnd: 9999,
        eventEnded: false
    });

    const md5 = require('md5');
    const base64 = (str) => {
        return Buffer.from(str, 'utf8').toString('base64')
    };

    useEffect(() => {
        if (searchParams.get('t') !== null) {
            api.get('/' + md5(base64(searchParams.get('t') + process.env.REACT_APP_OTHSV_API_SECRET)) + '/event/' + searchParams.get('t')).then(response => {
                setEvent(response.data);
                setOffset(moment(response.data.server_time).diff(moment(), 'seconds'));
                setLoading(false);
            }).catch(() => {
                console.clear();
                setEvent({event_pw: "error", start_time: "", end_time: ""});
                setLoading(false);
            });
        } else {
            setEvent({event_pw: "error", start_time: "", end_time: ""});
            setLoading(false)
        }

    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!eventStartTime.eventStarted) {
                let timeDiffStart = moment(event.start_time).diff(moment().add(offset,'seconds'), 'seconds');
                if (timeDiffStart >= 0) {
                    //console.log('tts: ' + timeDiffStart);
                    setEventStartTime({timeToStart: timeDiffStart, eventStarted: false});
                } else {
                    //console.log('event started');
                    setEventStartTime({timeToStart: 0, eventStarted: true});
                }
            }

            if (!eventEndTime.eventEnded) {
                let timeDiffEnd = moment(event.end_time).diff(moment().add(offset, 'seconds'), 'seconds');
                if (timeDiffEnd >= 0) {
                    //console.log('tte: ' + timeDiffEnd);
                    setEventEndTime({timeToEnd: timeDiffEnd, eventEnded: false});
                } else {
                    //console.log('event ended');
                    setEventEndTime({timeToEnd: 0, eventEnded: true});
                }
            }

        }, 1000);

        return () => clearInterval(interval);

    }, [event.end_time, event.start_time, eventEndTime.eventEnded, eventStartTime.eventStarted]);

    const Login = (details) => {
        if (md5(base64(details.password)) === event.event_pw) {
            setSession(true);
        } else {
            setError("Das eingegebene Passwort ist falsch.");
        }
    }

    const Logout = () => {
        setSession(false);
        console.log("Logout")
    }

    if (isLoading) {
        return (
            <Loading />
        );
    }

    if (event.event_pw !== "error") {
        if (session) {
            if (eventEndTime.eventEnded) {
                return (
                    <div className="App">
                        <CSSTransition in={true} timeout={300} appear={true} classNames="component" unmountOnExit>
                            <AfterEvent event={event}/>
                        </CSSTransition>
                    </div>
                );
            }

            if (eventStartTime.eventStarted) {
                return (
                    <CSSTransition in={true} timeout={300} appear={true} classNames="component" unmountOnExit>
                        <Player event={event}/>
                    </CSSTransition>
                );
            }

            return (
                <CSSTransition in={true} timeout={300} appear={true} classNames="component" unmountOnExit>
                    <BeforeEvent event={event} remainingSeconds={eventStartTime.timeToStart}/>
                </CSSTransition>
            );

        } else {
            return (
                <CSSTransition in={true} timeout={300} appear={true} classNames="component" unmountOnExit>
                    <LoginForm Login={Login} error={error} event={event}/>
                </CSSTransition>
            );
        }
    }

    return (
        <CSSTransition in={true} timeout={300} appear={true} classNames="component" unmountOnExit>
            <NoEvent/>
        </CSSTransition>
    );
}