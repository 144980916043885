import React from "react";
import logo from "../assets/360_logo_grey.svg";
import moment from "moment";

export default function BeforeEvent({event, remainingSeconds}){

    return (
        <div className="h-screen flex py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full flex flex-col m-auto space-y-8">
                <div>
                    <a target="_blank" href="https://over360view.de" rel="noreferrer">
                        <img className="mx-auto" width="130px"
                             src={logo} alt="Logo"/></a>

                    {(remainingSeconds > 7200) ? (
                        <>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sie sind etwas zu früh</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Das Event: <b>{event.description}</b> beginnt am <b>{moment(event.start_time).format('DD.MM.YYYY')} um {moment(event.start_time).format('HH:mm')}</b>.<br /> Bitte schauen Sie später nochmal vorbei.
                        </p>
                        </>
                    ) : (
                        <>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            {moment.utc(remainingSeconds*1000).format('HH:mm:ss')}
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Das Event: <b>{event.description}</b> beginnt um <b>{moment(event.start_time).format('HH:mm')}</b>.<br /> Bitte warten Sie oder schauen später nochmal vorbei.
                        </p>
                        </>
                    )
                    }
                </div>
            </div>
        </div>
    );
}