import React from "react";
import logo from "../assets/360_logo_grey.svg";

export default function AfterEvent({event}){
    return (
        <div className="h-screen flex py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full flex flex-col m-auto space-y-8">
                <div>
                    <a target="_blank" href="https://over360view.de" rel="noreferrer">
                        <img className="mx-auto" width="130px"
                             src={logo} alt="Logo"/></a>

                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Vielen Dank fürs zusehen!
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Das Event: <b>{event.description}</b> ist zu Ende.<br /> Wir bedanken uns fürs zusehen!
                    </p>
                </div>
            </div>
        </div>
    );
}