import React, {useEffect} from "react";
import lottie from "lottie-web";
import threesixty from "../assets/360.json";

export default function Onboarding({Onboard}){

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#three-sixty"),
            animationData: threesixty
        });
    }, []);

    return (
        <div className="h-screen flex py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full flex flex-col m-auto space-y-8">
                <div>
                    <div id="three-sixty" className="p-0 m-auto" style={{ width: 200, height: "100%" }}  alt="Animation"/>

                    <h2 className="m-0 text-center text-3xl font-extrabold text-gray-900">
                        Bewegung und Ausrichtung
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Sie haben die Möglichkeit, uns Zugriff auf Ihre Bewegungs und Ausrichtungsdaten zu geben.
                        <br />Dadurch können Sie durch bewegen Ihres Smartphones den 360 Grad Videostream ausrichten.
                        <br />Um diese Einwilligung zu bearbeiten, müssen Sie in die Browsereinstellungen Ihres Smartphones.
                        <br />
                        <br />Sie werden nur einmal nach dieser Zugriffsberechtigung gefragt. Zur Nutzung ist dies nicht zwingend erforderlich.
                    </p>
                </div>
                <div>
                    <button onClick={Onboard}
                            className="m-auto max-w-md flex py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Verstanden
                    </button>
                </div>
            </div>
        </div>
    );
}