import React, {useState} from "react";
import logo from "../assets/360_logo_grey.svg";

function LoginForm({Login, error, event}) {
    const [details, setDetails] = useState({password: ""});

    const submitHandler = (e) => {
        e.preventDefault();
        Login(details);
    }

    return (
        <div className="h-screen flex py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full flex flex-col m-auto space-y-8">
                <div>
                    <a target="_blank" href="https://over360view.de" rel="noreferrer">
                        <img className="mx-auto" width="130px"
                             src={logo} alt="Logo"/></a>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        {event.description}
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Geben Sie das Passwort ein, um dem Event beizutreten.
                    </p>

                    <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                        {(error !== "") ? (
                            <div role="alert">
                                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                                    Fehler
                                </div>
                                <div
                                    className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                    <p>{error}</p>
                                </div>
                            </div>
                        ) : ""}

                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <input id="password" name="password" type="password" required
                                       className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                       onChange={e => setDetails({...details, password: e.target.value})}
                                       value={details.password}
                                       placeholder="Passwort"/>
                            </div>
                        </div>

                        <div>
                            <button type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Event beitreten
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        <a target="_blank" href="https://over360view.de/impressum/" rel="noreferrer">Impressum</a> | <a target="_blank" href="https://over360view.de/datenschutz/" rel="noreferrer">Datenschutz</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;