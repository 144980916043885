import React, {useEffect, useState} from "react";
import {isIOS, isMacOs, isMobileSafari, isSafari, isMobile} from "react-device-detect";
import VideoJS from "./players/VideoJS";
import Clappr360 from "./players/Clappr360";
import logo from "../assets/360_logo_grey.svg";
import "../assets/player.css"
import api from "../api/events";
import {Buffer} from "buffer";
import Loading from "./Loading";
import Onboarding from "./Onboarding";

export default function Player({event}) {

    const [isAppleDevice] = useState(isSafari || isIOS || isMacOs || isMobileSafari);
    const [player, setPlayer] = useState('');
    const [location, setLocation] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState({ isError: false, error: '' });
    const [isOnboarded, setOnboarded] = useState(false);

    const switchPlayer = () => {
        if (player === '1') {
            setPlayer('2');
        } else {
            setPlayer('1');
        }
    }

    const Onboard = () => {
      setOnboarded(true);
    }

    const md5 = require('md5');
    const base64 = (str) => {
        return Buffer.from(str, 'utf8').toString('base64')
    };

    useEffect(() => {
        api.get('/' + md5(base64(event.location_id + process.env.REACT_APP_OTHSV_API_SECRET)) + '/location/' + event.location_id).then(response => {
            setLocation(response.data);
            setLoading(false);
        }).catch(() => {
            console.clear();
            setError({isError: true, error: 'Stream konnte nicht gefunden werden. Bitte versuchen Sie es erneut, auf einem anderen Browser bzw. einem anderen Gerät.'})
            setLoading(false);
        });
    }, [event])

    useEffect(() => {
        if (isAppleDevice) {
            setPlayer('1');
        } else {
            setPlayer('2');
        }
    }, [isAppleDevice]);

    if (isLoading){
        return (
            <Loading />
        );
    }

    if (isMobile && !isOnboarded){
        return (
            <Onboarding Onboard={Onboard}/>
        );
    }

    return (
        <div className="bg-neutral-900 h-full flex py-12 px-4 sm:px-6 lg:px-8">
            <div className="lg:max-w-5xl w-full h-full flex flex-col m-auto space-y-8">
                <div>
                    <a target="_blank" href="https://over360view.de" rel="noreferrer">
                        <img className="mx-auto" width="130px"
                             src={logo} alt="Logo"/></a>
                    <h2 className="mt-6 text-center md:text-2xl lg:text-3xl font-extrabold text-zinc-300">
                        {event.description}
                    </h2>

                    <br/>

                    <div className="player-window">
                        {(player === '1') ? (
                            <VideoJS
                                playerId="vid1"
                                source={location.hls_url.replace(/\\\//g, "/")}
                                isAppleDevice={isAppleDevice}
                            />
                        ) : (
                            <Clappr360
                                playerId="vid2"
                                videoSource={location.hls_url.replace(/\\\//g, "/")}
                            />
                        )}
                    </div>

                    <br />

                    <p className="mt-2 text-center text-sm text-gray-600">
                        {(isMobile) ? "Benutzen Sie Ihren Finger" : (player === '1') ? "Benutzen Sie Ihre Maus oder Pfeiltasten" : "Benutzen Sie Ihre Maus"}
                        , um sich in dem Video zu bewegen.
                    </p>

                    <br />

                    <p className="mt-2 text-center text-sm text-gray-600">
                        Bei Problemen, versuchen Sie es mit unserem anderen Player über den Button „Player wechseln”. <br />Falls das nicht funktionieren sollte, nutzen Sie bitte Google Chrome auf einem PC oder Mac.
                    </p>

                    <br />

                    <div>
                        <button onClick={switchPlayer}
                                className="m-auto max-w-md flex py-1 px-3 border border-transparent text-sm font-small rounded-md text-white bg-zinc-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Player wechseln
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}