import React from "react";
import {BrowserRouter, Router, Routes, Route, useSearchParams } from "react-router-dom";
import Main from "./Main";

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
  );
}
